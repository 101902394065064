



















import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import PaginatedTable from '@bertazzoni/back/components/PaginatedTable.vue'
import TagsService from '@bertazzoni/common/src/services/TagsService'
import { Tag } from '@bertazzoni/common/src/models/tag.model'
import { TableInfo } from '@bertazzoni/back/models/table.model'
import { isErrorNotFound } from '@bertazzoni/common/models/user.error'

@Component({
  components: {
    Loader,
    PaginatedTable
  }
})
export default class TagList extends Vue {
  private pageLoaded = false
  private loading = false
  private searchField = ''
  private tagList: Tag[] = []
  private tableInfoTag: TableInfo = new TableInfo()

  async created(): Promise<void> {
    this.setTableInfo()
    await this.getModulList()
    this.pageLoaded = true
  }
  async getModulList(): Promise<void> {
    this.loading = true
    let result
    try {
      result = await TagsService.findAllPaginated({
        limit: this.tableInfoTag.paginInfo.limit,
        offset: this.tableInfoTag.paginInfo.offset,
        searchingText: this.searchField
      })
    } catch (e) {
      if (!isErrorNotFound(e)) {
        throw e
      }
    }
    this.tagList = result.results
    this.tableInfoTag.paginInfo.totalRow = result.total
    this.loading = false
  }
  setTableInfo(): void {
    this.tableInfoTag.getCustomTable().displayButton = false
    this.tableInfoTag.getCustomTable().searchAndCreate = true
    this.tableInfoTag.getCustomTable().columns = ['Tag category', 'Tag names']
    this.tableInfoTag.getCustomTable().displayTitle = true
  }
  async newLimit(limit: number) {
    this.tableInfoTag.paginInfo.limit = limit
    this.newPage(0, 1)
  }
  async newPage(offset: number, currentPage: number) {
    this.tableInfoTag.paginInfo.offset = offset
    this.tableInfoTag.paginInfo.currentPage = currentPage
    await this.getModulList()
  }
  async newSearch(searchField: string) {
    if (searchField !== this.searchField) {
      this.searchField = searchField
      this.newPage(0, 1)
    }
  }
  goAddTag(): void {
    this.$router.push('/new-tags')
  }
}
